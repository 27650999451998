import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-5cec377f"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "list-content"
};
const _hoisted_2 = {
  class: "list-wrap"
};
const _hoisted_3 = {
  class: "content-footer content-footer-pc"
};
const _hoisted_4 = {
  class: "content-footer content-footer-mobile"
};
import ListItem from './list-item.vue';
import { ref } from 'vue';
import useListStore from '@/store/modules/list.js';
export default {
  __name: 'list-content',
  props: {
    pageInfo: {
      type: Object,
      default: () => ({
        currentPage: 1,
        pageSize: 9
      })
    }
  },
  emits: ['currentChangeEmit', 'sizeChangeEmit'],

  setup(__props, {
    emit
  }) {
    const props = __props;
    const listStore = useListStore(); // 处理分页

    function handleCurrentChange(currentPage) {
      emit('currentChangeEmit', { ...props.pageInfo,
        currentPage
      });
      window.scrollTo(0, 0);
    }

    function handleSizeChange(pageSize) {
      emit('sizeChangeEmit', { ...props.pageInfo,
        pageSize
      });
    }

    return (_ctx, _cache) => {
      const _component_el_pagination = _resolveComponent("el-pagination");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(listStore).dataList, (item, index) => {
        return _openBlock(), _createBlock(ListItem, {
          key: index,
          info: item,
          class: "list-item"
        }, null, 8, ["info"]);
      }), 128))]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_pagination, {
        layout: "total, prev, pager, next, jumper",
        "current-page": __props.pageInfo.currentPage,
        "page-size": 9,
        total: _unref(listStore).totalCount,
        onSizeChange: handleSizeChange,
        onCurrentChange: handleCurrentChange,
        background: true
      }, null, 8, ["current-page", "total"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_pagination, {
        layout: "total, prev, pager, next",
        "current-page": __props.pageInfo.currentPage,
        "page-size": 9,
        total: _unref(listStore).totalCount,
        onSizeChange: handleSizeChange,
        onCurrentChange: handleCurrentChange,
        background: true,
        small: "",
        "pager-count": 5
      }, null, 8, ["current-page", "total"])])]);
    };
  }

};