import { defineStore } from 'pinia'
import { getDocumentList } from '@/service/list/list'
const useListStore = defineStore('list', {
  state: () => ({
    filterInfo: {},
    pageInfo: { currentPage: 1, pageSize: 9 },
    totalCount: 0,
    dataList: []
  }),
  getters: {},
  actions: {
    async fetchData() {
      const params = {
        page: this.pageInfo.currentPage,
        size: this.pageInfo.pageSize
      }
      const res = await getDocumentList(params)
      this.totalCount = res.result.total_count
      this.dataList = res.result.data
    },

    // // filter
    // changeFilterInfo(filterInfo) {
    //   console.log('过滤搜索', filterInfo)
    //   this.filterInfo = filterInfo
    //   this.pageInfo.currentPage = 1
    //   this.fetchData()
    // },

    // page
    changePageInfo(pageInfo, filterInfo) {
      this.pageInfo = pageInfo
      if (filterInfo) {
        this.filterInfo = filterInfo
      }
      this.fetchData()
    }
  }
})

export default useListStore
