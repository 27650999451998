import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-f8139fb2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "list"
};
import ListNavbar from './cpns/list-navbar.vue'; // import ListSearch from './cpns/list-search.vue'

import { ref } from 'vue';
import ListContent from './cpns/list-content.vue';
import useListStore from '@/store/modules/list.js';
export default {
  __name: 'list',

  setup(__props) {
    const listStore = useListStore();
    listStore.fetchData();
    const pageInfo = ref({
      currentPage: 1,
      pageSize: 9
    });

    if (pageInfo.value.currentPage != listStore.pageInfo.currentPage) {
      pageInfo.value.currentPage = listStore.pageInfo.currentPage;
    } // 改变页码


    function handleCurrentChangeEmit(page) {
      pageInfo.value = page;
      listStore.changePageInfo(page);
    } // 改变每页个数


    function handleSizeChangeEmit(page) {
      pageInfo.value = page;
      listStore.changePageInfo(page);
    }

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(ListNavbar), _createVNode(ListContent, {
        onCurrentChangeEmit: handleCurrentChangeEmit,
        onSizeChangeEmit: handleSizeChangeEmit,
        pageInfo: pageInfo.value,
        "onUpdate:pageInfo": _cache[0] || (_cache[0] = $event => pageInfo.value = $event)
      }, null, 8, ["pageInfo"])]);
    };
  }

};